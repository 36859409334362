import Cookies from 'js-cookie';

import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';
import { CpInfoType } from '@/types/cpType';
import { UserInfo } from '@/types/user';

interface InitState {
    isLogin?: boolean;
    userInfo?: UserInfo;
    isCp?: boolean;
    cpInfo?: CpInfoType;
}

interface UserState {
    isLogin: boolean;
    userInfo: UserInfo;
    setInitUserInfo: (params: UserInfo) => void;
    updateUserInfo: (params: Partial<UserInfo>) => void;
    setInitCpInfo: (params: CpInfoType) => void;
    updateCpInfo: (params: Partial<CpInfoType>) => void;
    isCp: boolean;
    cpInfo: CpInfoType;
}

let useUserStore: StoreWithSelectors<UserState>;

const initialState = (set: (updater: (state: UserState) => Partial<UserState>) => void) => ({
    isLogin: false,
    userInfo: {
        access_token: '',
        refresh_token: '',
        avatar: '',
        nickname: '',
        description: '',
        gender: 1 as 1 | 2,
        birthday: '',
        user_id: '',
        account: '',
        is_completed: false,
        is_couple: false,
        is_phone_binding: false,
        email: '',
        is_new: false,
        status: 0 as 0 | 1 | 2
    },
    setInitUserInfo: (params: UserInfo) => {
        set(() => {
            return {
                isLogin: true,
                userInfo: params,
                token: params.access_token
            };
        });
    },
    updateUserInfo: (params: Partial<UserInfo>) => {
        set(state => {
            return {
                userInfo: {
                    ...state.userInfo,
                    ...params
                }
            };
        });
    },
    isCp: false,
    cpInfo: {} as CpInfoType,
    setInitCpInfo: (params: CpInfoType) => {
        Cookies.set('isCp', 'on');
        set(() => {
            return {
                isCp: true,
                cpInfo: params
            };
        });
    },
    updateCpInfo: (params: Partial<CpInfoType>) => {
        set(state => {
            return {
                cpInfo: {
                    ...state.cpInfo,
                    ...params
                }
            };
        });
    }
});

const createUserStore = (init: InitState) => {
    useUserStore = initStore<UserState>(initialState, init);
};

export { createUserStore, useUserStore };
