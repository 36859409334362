import axios from 'axios';
import imageCompression from 'browser-image-compression';

import { useResourceStore } from '@/store/resource';
import { UploadInfo } from '@/types/common';

export const uploadFileToS3 = async (file: File, upload: UploadInfo) => {
    const formData = new FormData();
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    };
    try {
        const compressedFile = file.type.includes('image')
            ? await imageCompression(file, options)
            : file;

        formData.append('Content-Type', file.type || 'image/png');
        formData.append('key', upload.fields.key);
        formData.append('bucket', upload.fields.bucket);
        formData.append('X-Amz-Algorithm', upload.fields['X-Amz-Algorithm']);
        formData.append('X-Amz-Credential', upload.fields['X-Amz-Credential']);
        formData.append('X-Amz-Date', upload.fields['X-Amz-Date']);
        formData.append('Policy', upload.fields.Policy);
        formData.append('X-Amz-Signature', upload.fields['X-Amz-Signature']);
        formData.append('file', compressedFile);

        useResourceStore.getState().switchUploadProgress(true);
        const response = await axios.post(upload.url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                if (!progressEvent.total) return;
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                useResourceStore.getState().setProgress(percentCompleted);
            }
        });
        useResourceStore.getState().switchUploadProgress(false);
        return response;
    } catch (error) {
        console.error('Upload failed:', error);
        throw new Error('Upload failed');
    }
};
