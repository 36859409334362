export const VideoAccept = ['video/mp4', 'video/quicktime', 'video/mp3', 'video/m4v', 'video/aac'];
export const ImageAccept = ['image/jpeg', 'image/png', 'image/heif', 'image/jpg'];

export const VideoMaxSize = 500 * 1024 * 1024; // 500MB
export const EpisodeMaxSize = 500 * 1024 * 1024; // 500MB
export const TrailerMaxSize = 500 * 1024 * 1024; // 500MB
export const ImageMaxSize = 10 * 1024 * 1024; // 10MB

export const VideoDuration = 440; // 7min
export const EpisodeDuration = 440; // 7min
export const TrailerDuration = 440; // 7min
