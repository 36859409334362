'use client';

import { Turnstile } from '@marsidev/react-turnstile';
import { useLocale } from 'next-intl';

import { LangCodeMap } from '@/assets/lang/langCodeMap';
import { useBaseStore } from '@/store/base';

interface TurnstileWidgetProps {
    onVerify: (token: string) => void;
}

function TurnstileWidget({ onVerify }: TurnstileWidgetProps) {
    const locale = useLocale();
    const sitKey =
        useBaseStore.getState().envParams === 'prod'
            ? process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY_PROD
            : process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY_DEV;
    return (
        <Turnstile
            siteKey={sitKey!}
            onSuccess={onVerify}
            options={{
                theme: 'dark',
                size: 'normal',
                language: LangCodeMap[locale as keyof typeof LangCodeMap]
            }}
        />
    );
}

export default TurnstileWidget;
