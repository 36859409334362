import { KeyboardEvent } from 'react';

import style from './baseInput.module.scss';
type EmailInputProps = {
    type?: 'email';
    value: string;
};

type TextInputProps = {
    type?: 'text';
    value: string;
};

type NumberInputProps = {
    type?: 'number';
    value: number;
};

type BaseInputProps = {
    label?: string;
    placeholder?: string;
    className?: string;
    onBlur?: () => void;
    onChange?: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    inputmode?: React.HTMLAttributes<HTMLLIElement>['inputMode'];
    frontEl?: JSX.Element;
    backEl?: JSX.Element;
    maxLength?: number;
    pattern?: string;
    errorMessage?: string;
};

export type InputProps = BaseInputProps & (TextInputProps | NumberInputProps | EmailInputProps);

function BaseInput({
    label,
    type = 'text',
    className,
    onChange,
    frontEl,
    backEl,
    inputmode,
    errorMessage,
    ...rest
}: InputProps) {
    return (
        <div className={className}>
            {label && <label className={style.label}>{label}</label>}
            <div className={style.inputBlock}>
                {frontEl}
                <input
                    className={`${style.baseInput} ${errorMessage ? style.error : ''}`}
                    onChange={e => onChange && onChange(e.target.value)}
                    type={type}
                    {...(inputmode ? { inputMode: inputmode } : {})}
                    {...rest}
                />
                {backEl}
            </div>
            <div className={style.errorMessage}>{errorMessage}</div>
        </div>
    );
}

export default BaseInput;
