import { fetcher } from '@/lib/fetcher';
import {
    ChatInfo,
    ChatList,
    ChatGiftItem,
    UnlockMedia,
    ChatPrice,
    ChatGroupSetting
} from '@/types/chat';
import { UploadUserImageResponse } from '@/types/common';

export const getRecommendList = () => {
    return fetcher<ChatList, null>({
        url: '/chat/v1/recommend-list',
        method: 'GET'
    });
};

export const getChatInfo = ({ role, to }: { role: 'cp' | 'user'; to: string }) => {
    return fetcher<ChatInfo, { role: 'cp' | 'user'; to: string }>({
        url: '/chat/v1/session',
        method: 'GET',
        data: {
            role,
            to
        }
    });
};

export const getChatList = ({
    role,
    page,
    limit
}: {
    role: 'cp' | 'user';
    page: number;
    limit: number;
}) => {
    return fetcher<ChatInfo[], { role: 'cp' | 'user'; page: number; limit: number }>({
        url: '/chat/v1/session-list',
        method: 'GET',
        data: {
            role,
            page,
            limit
        }
    });
};

export const getChatPrice = () => {
    return fetcher<{ message: number; voice_chat: number }, null>({
        url: '/chat/v1/message/price',
        method: 'GET'
    });
};

export const updateChatPrice = (data: { message?: number; voice_chat?: number }) => {
    return fetcher<null, { message?: number; voice_chat?: number }>({
        url: '/chat/v1/message/price',
        method: 'PUT',
        data
    });
};

export const getChatGiftCategory = (sessionId: string) => {
    return fetcher<ChatGiftItem[], null>({
        url: `/chat/v1/session/${sessionId}/gift/category`,
        method: 'GET'
    });
};

export const getChatGiftList = (sessionId: string, categoryId: number) => {
    return fetcher<ChatGiftItem[], null>({
        url: `/chat/v1/session/${sessionId}/gift/category/${categoryId}`,
        method: 'GET'
    });
};

export const uploadMedia = ({ type }: { type: string }) => {
    return fetcher<UploadUserImageResponse, { type: string }>({
        url: `/chat/v1/${type}/upload-url`,
        method: 'POST'
    });
};

export const unLockMedia = ({ messageId, sessionId }: { messageId: string; sessionId: string }) => {
    return fetcher<UnlockMedia, { session_id: string; message_id: string }>({
        url: '/chat/v1/unlock-message',
        method: 'POST',
        data: {
            session_id: sessionId,
            message_id: messageId
        }
    });
};

export const getUnreadCount = ({ role }: { role: 'user' | 'cp' }) => {
    return fetcher<{ count: number }, { role: string }>({
        url: '/chat/v1/session/unread-count',
        method: 'GET',
        data: {
            role
        }
    });
};

export const getChatAllPrice = () => {
    return fetcher<ChatPrice, null>({
        url: '/chat/v1/message/me/price',
        method: 'GET'
    });
};
export const getChatGroupSetting = () => {
    return fetcher<ChatGroupSetting, null>({
        url: '/chat/v1/setting',
        method: 'GET'
    });
};
