import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import React from 'react';

import IconPencil from './img/pencil.svg';

import style from './editAvatar.module.scss';

interface Props {
    src: string | StaticImport;
}

function EditAvatar({ src }: Props) {
    return (
        <div className={style.editAvatar}>
            <div className={style.avatarBox}>
                <Image
                    width={96}
                    height={96}
                    alt="editAvatar"
                    src={src}
                    className={style.avatar}
                    priority
                />
                <div className={style.edit}>
                    <IconPencil />
                </div>
            </div>
        </div>
    );
}

export default EditAvatar;
