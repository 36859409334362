import Cookies from 'js-cookie';

import { getApiPath } from '@/utils/handleENV';
import { logout } from '@/utils/logout';

interface SuccessResponse<T> {
    status: 200;
    data: T;
}

interface ErrorResponse {
    status: 400;
    errorMsg: string;
}

type ApiResponse<T> = SuccessResponse<T> | ErrorResponse;

interface Fetcher<TData> {
    url?: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    headers?: Record<string, string>;
    data?: TData;
    timeout?: number;
}

const handleUnauthorized = async () => {
    if (typeof window !== 'undefined') {
        setTimeout(async () => {
            await logout();
            window.location.href = '/';
        }, 1000);
    }
};

export const fetcher = async <TResponse, TData>(
    { url, method = 'GET', headers = {}, data, timeout = 30 }: Fetcher<TData>,
    option = {},
    serverAccess?: string
): Promise<ApiResponse<TResponse>> => {
    const controller = new AbortController();
    setTimeout(() => {
        controller.abort();
    }, timeout * 1000);

    const apiPath = getApiPath();
    let apiUrl = `${apiPath}${url}`;

    const requestOptions: RequestInit = {
        method: method || 'GET',
        headers: {
            Authorization: `Bearer ${serverAccess || Cookies?.get('access')}` || '',
            'content-type': 'application/json',
            'X-Source': typeof window !== 'undefined' ? 'NextJS-ClientSide' : 'NextJS-ServerSide',
            ...headers
        },
        signal: controller.signal,
        cache: 'no-store',
        ...option
    };

    if (method !== 'GET' && data) {
        requestOptions.body = JSON.stringify(data);
    } else if (data) {
        const queryParams = new URLSearchParams(data as Record<string, string>).toString();
        apiUrl += `?${queryParams}`;
    }

    const response = await fetch(apiUrl, requestOptions);

    if (response.status === 401) {
        handleUnauthorized();
    }

    if (response.ok) {
        try {
            const data = response.statusText === 'No Content' ? '' : await response.json();
            return {
                status: 200,
                data
            };
        } catch {
            return {
                status: 200,
                data: '' as TResponse
            };
        }
    } else {
        try {
            const error = await response.json();
            return {
                status: 400,
                errorMsg: error.msg
            };
        } catch (e) {
            // eslint-disable-next-line
            console.log('error:', e);
            return {
                status: 400,
                errorMsg: '系統異常，請稍後再試！'
            };
        }
    }
};
