import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';

interface BaseState {
    openUploadProgress: boolean;
    switchUploadProgress: (status: boolean) => void;
    progress: number;
    setProgress: (num: number) => void;
}

let useResourceStore: StoreWithSelectors<BaseState>;

const initialState = (set: (updater: (state: BaseState) => Partial<BaseState>) => void) => ({
    openUploadProgress: false,
    switchUploadProgress: (status: boolean) => {
        set(() => ({
            openUploadProgress: status
        }));
    },
    progress: 0,
    setProgress: (num: number) => {
        set(() => ({
            progress: num
        }));
    }
});

const createResourceStore = () => {
    useResourceStore = initStore<BaseState>(initialState);
};

export { createResourceStore, useResourceStore };
