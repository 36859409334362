export const convertSecondsToTime = (duration: number): string => {
    const totalSeconds = Math.round(duration);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};

export const toDateString = (date: string): string => {
    const datetime = new Date(date);
    const yyyy = datetime.getFullYear();
    const mm = String(datetime.getMonth() + 1).padStart(2, '0');
    const dd = String(datetime.getDate()).padStart(2, '0');
    const hh = String(datetime.getHours()).padStart(2, '0');
    const MM = String(datetime.getMinutes()).padStart(2, '0');
    return `${yyyy}.${mm}.${dd} ${hh}:${MM}`;
};

export const convertTimeUtc = (utc: string) => {
    const date = new Date(utc);
    const yyyy = date.getFullYear();
    const MM = date.getMonth() + 1;
    const dd = date.getDate();
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();

    return `${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`;
};

// 格式化日期 預設 YYYY-MM-DD
export const formatDate = (date: Date, format = 'YYYY-MM-DD') => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return format.replace('YYYY', `${year}`).replace('MM', month).replace('DD', day);
};

export const getUpdateTime = (date: Date) => {
    const MM = date.getMonth() + 1;
    const dd = date.getDate();
    const hh = date.getHours();
    const mm = date.getMinutes();

    return `${MM}/${dd} ${hh}:${mm}`;
};

export function isAgeValid(birthday: string) {
    const age = new Date().getFullYear() - new Date(birthday).getFullYear();
    return age >= 18;
}

export function formatDateNotify(dateString: string) {
    const date = new Date(dateString);
    const now = new Date();

    // 計算時間差（毫秒）
    const timeDifference = now.getTime() - date.getTime();

    // 24 小時之內
    if (timeDifference < 24 * 60 * 60 * 1000) {
        return date.toTimeString().slice(0, 5);
    }

    // 超過 24 小時
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
        return '昨天';
    }

    // 超過 48 小時到七天之內
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);
    if (date > sevenDaysAgo) {
        const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
        return daysOfWeek[date.getDay()];
    }

    // 超過七天到一年前
    const lastYear = new Date(now);
    lastYear.setFullYear(now.getFullYear() - 1);
    if (date > lastYear) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}/${day}`;
    }

    // 一年以上
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
}

export const chatFormatDate = (dateString: string) => {
    const date = new Date(dateString);

    const now = new Date();

    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.getTime() >= today.getTime()) {
        return '今日';
    } else if (date.getTime() >= yesterday.getTime()) {
        return '昨日';
    } else {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}/${month}/${day}`;
    }
};
