import style from './baseDatePicker.module.scss';

interface Props {
    date: string;
    onChange?: (value: string) => void;
    label?: string;
    className?: string;
    disabled?: boolean;
    max?: string | number;
}

function BaseDatePicker({ className, label, date, onChange, disabled, max }: Props) {
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.value);
    };

    return (
        <div className={className}>
            {label ? <label className={style.label}>{label}</label> : null}
            <input
                className={style.baseDatePicker}
                type="date"
                value={date}
                onChange={handleDateChange}
                disabled={disabled}
                max={max}
                aria-label={'here'}
            />
        </div>
    );
}

export default BaseDatePicker;
