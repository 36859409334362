import React from 'react';

import { ImageAccept } from '@/utils/fileConstant';

interface ImageUploaderProps {
    onFileLoaded: (imageData: string | null, file?: File) => void;
    accept?: string[];
    id?: string;
    disabled?: boolean;
    children: React.ReactNode;
}

function FileUploader({
    accept = ImageAccept,
    onFileLoaded,
    id = 'fileUploader',
    disabled,
    children
}: ImageUploaderProps) {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;

        if (file) {
            if (accept.includes(file.type)) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (typeof e.target?.result === 'string') {
                        onFileLoaded(e.target.result, file);
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('File read error: result not a string');
                        onFileLoaded(null);
                    }
                };
                reader.readAsDataURL(file);
            } else {
                // eslint-disable-next-line no-console
                console.log(`Unsupported file type: ${file.type}`);
                onFileLoaded(null);
            }
        } else {
            // eslint-disable-next-line no-console
            console.log('No file chosen or file read error');
            onFileLoaded(null);
        }
    };

    return (
        <div>
            <label htmlFor={id}>{children}</label>
            <input
                style={{ display: 'none' }}
                type="file"
                id={id}
                name="file"
                accept={accept.join(',')}
                onChange={handleFileChange}
                disabled={disabled}
            />
        </div>
    );
}

export default FileUploader;
