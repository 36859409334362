import { initializeApp } from 'firebase/app';
import { getMessaging, Messaging } from 'firebase/messaging';

import { useBaseStore } from '@/store/base';

let messagingInstance: Messaging | null = null;

export const initFCM = () => {
    const firebaseConfig = {
        apiKey:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_APIKEY_PROD
                : process.env.NEXT_PUBLIC_FCM_APIKEY_DEV,
        authDomain:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_AUTHDOMAIN_PROD
                : process.env.NEXT_PUBLIC_FCM_AUTHDOMAIN_DEV,
        projectId:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_PROJECTID_PROD
                : process.env.NEXT_PUBLIC_FCM_PROJECTID_DEV,
        storageBucket:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_STORAGEBUCKET_PROD
                : process.env.NEXT_PUBLIC_FCM_STORAGEBUCKET_DEV,
        messagingSenderId:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_MESSAGINGSENDERID_PROD
                : process.env.NEXT_PUBLIC_FCM_MESSAGINGSENDERID_DEV,
        appId:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_APPID_PROD
                : process.env.NEXT_PUBLIC_FCM_APPID_DEV,
        measurementId:
            useBaseStore.getState().envParams === 'prod'
                ? process.env.NEXT_PUBLIC_FCM_MEASUREMENTID_PROD
                : process.env.NEXT_PUBLIC_FCM_MEASUREMENTID_DEV
    };
    const app = initializeApp(firebaseConfig);
    messagingInstance = getMessaging(app);
    return messagingInstance;
};

export const getMessagingInstance = () => {
    return messagingInstance || initFCM();
};
