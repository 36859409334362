import { deleteToken } from 'firebase/messaging';
import Cookies from 'js-cookie';

import { getMessagingInstance } from './fcm';

export const logout = async () => {
    if (typeof window !== 'undefined') {
        Cookies.remove('access');
        Cookies.remove('refresh');
        Cookies.remove('fct');
        Cookies.remove('isCp');
        localStorage.removeItem('visit');
        sessionStorage.removeItem('hasOver18');
        const messaging = getMessagingInstance();
        try {
            const res = await deleteToken(messaging);
            return res;
        } catch (e) {
            //eslint-disable-next-line
            console.log(e);
        }
    }
};
