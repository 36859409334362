import { fetcher } from '@/lib/fetcher';
import { UploadUserImageResponse } from '@/types/common';

interface UpdateUserInfoInput {
    avatar: string;
    nickname: string;
    description: string;
    gender: number;
    birthday: string;
}

export const updateUserInfo = (data: UpdateUserInfoInput) => {
    return fetcher<null, UpdateUserInfoInput>({
        url: '/user/v1/me',
        method: 'PUT',
        data: data
    });
};

/** 獲得封面預簽名上傳URL */
export const getUploadUserAvatarUrl = () => {
    return fetcher<UploadUserImageResponse, null>({
        url: '/user/v1/upload-url',
        method: 'POST'
    });
};

export interface DeleteUserAccountInput {
    validate: string;
}

/** 刪除個人帳號 */
export const deleteUserAccount = (data: DeleteUserAccountInput) => {
    return fetcher<null, null>({
        url: `/user/v1/me?validate=${data.validate}`,
        method: 'DELETE'
    });
};

interface GetUserSettingResponse {
    invoice: {
        is_donate: boolean;
        email: string;
    };
}

/** 拿到個人設定 */
export const getUserSetting = () => {
    return fetcher<GetUserSettingResponse, null>({
        url: '/user/v1/me/setting',
        method: 'GET'
    });
};

interface UpdateUserSettingInput {
    invoice: {
        is_donate: boolean;
        email: string;
    };
}

/** 更新個人資料 */
export const updateUserSetting = (data: UpdateUserSettingInput) => {
    return fetcher<null, UpdateUserSettingInput>({
        url: '/user/v1/me/setting',
        method: 'PUT',
        data
    });
};
