import CircularProgress from '@mui/material/CircularProgress';

interface LoadingType {
    size?: number;
    thickness?: number;
}

function Loading({ size = 24, thickness = 6 }: LoadingType) {
    return <CircularProgress size={size} thickness={thickness} />;
}

export default Loading;
