import { useBaseStore } from '@/store/base';

export const getApiPath = () => {
    const env = process.env.ENV || useBaseStore.getState().envParams;
    const apiPathList: Record<string, string> = {
        dev: process.env.NEXT_PUBLIC_API_DEV || '',
        prod: process.env.NEXT_PUBLIC_API_PROD || ''
    };
    return apiPathList[env];
};
