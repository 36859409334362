import { fetcher } from '@/lib/fetcher';
import { UserInfo, Token } from '@/types/user';

export const sendEmailforLogin = (email: string) => {
    return fetcher<{ msg: string }, { email: string }>({
        url: '/auth/send-email',
        method: 'POST',
        data: {
            email
        }
    });
};

export const loginWithMail = ({ email, validateCode }: { email: string; validateCode: string }) => {
    return fetcher<Token, { email: string; code: string }>({
        url: '/auth/email/login',
        method: 'POST',
        data: {
            email,
            code: validateCode
        }
    });
};

export const getUserInfoByToken = (token: string) => {
    return fetcher<UserInfo, null>({
        url: '/user/v1/me',
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const refreshToken = (token: string) => {
    return fetcher<Token, null>({
        url: '/auth/refresh-token',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getCountryCodeList = () => {
    return fetcher<{ [key: string]: string }, null>({
        url: '/auth/country-code',
        method: 'GET'
    });
};

export const sendSms = ({
    country,
    number,
    cfTurnstileResponse
}: {
    country: string;
    number: string;
    cfTurnstileResponse: string;
}) => {
    return fetcher<null, { country: string; number: string; 'cf-turnstile-response': string }>({
        url: '/auth/v2/send-sms',
        method: 'POST',
        data: {
            country,
            number,
            'cf-turnstile-response': cfTurnstileResponse
        }
    });
};

export const bindPhone = ({
    country,
    number,
    code
}: {
    country: string;
    number: string;
    code: string;
}) => {
    return fetcher<null, { country: string; number: string; code: string }>({
        url: '/auth/mobile-phone/bind',
        method: 'POST',
        data: {
            country,
            number,
            code
        }
    });
};

interface GetBindStatusResponse {
    google: {
        is_binding: boolean;
        identifier: string;
    };
    email: {
        is_binding: boolean;
        identifier: string;
    };
    phone: {
        is_binding: boolean;
        identifier: string;
    };
}

export const getBindingStatus = ({ serverAccess }: { serverAccess?: string }) => {
    return fetcher<GetBindStatusResponse, null>(
        {
            url: '/auth/binding-status',
            method: 'GET'
        },
        {},
        serverAccess
    );
};
