'use client';
import { ReactNode } from 'react';

import Loading from '@/components/loading/Loading';

import style from './baseButton.module.scss';

interface BaseButton {
    children: ReactNode;
    type?: 'normal' | 'outline';
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    loading?: boolean;
    disabled?: boolean;
    allowDisabledOnClick?: boolean;
}

function BaseButton({
    children,
    type = 'normal',
    className,
    onClick,
    loading = false,
    disabled = false,
    allowDisabledOnClick = false
}: BaseButton) {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if ((!allowDisabledOnClick && disabled) || loading) return;
        if (onClick) onClick(event);
    };

    return (
        <div
            className={`${style.baseButton} ${style[type]} ${className} ${
                disabled || loading ? style.disabled : ''
            }`}
            onClick={handleClick}
        >
            {loading ? (
                <div className={style.loading}>
                    <Loading />
                </div>
            ) : (
                children
            )}
        </div>
    );
}

export default BaseButton;
