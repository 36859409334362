import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode, useEffect, useRef } from 'react';

import BackIcon from './img/back.svg';
import CloseIcon from './img/close.svg';

import style from './fullScreenPopup.module.scss';

interface Props {
    isOpen: boolean;
    children: ReactNode;
    back?: () => void;
    close?: () => void;
    title?: JSX.Element;
    hiddenClose?: boolean;
    hiddenTop?: boolean;
    send?: () => void;
    zIndex?: number;
    sendText?: string;
}

function FullScreenPopup({
    isOpen,
    children,
    back,
    close,
    title,
    hiddenClose,
    hiddenTop = false,
    send,
    zIndex = 1200,
    sendText
}: Props) {
    const keepBodyState = useRef('');

    useEffect(() => {
        if (isOpen) {
            keepBodyState.current = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } else {
            if (!keepBodyState.current) return;
            document.body.style.overflow = keepBodyState.current;
        }
    }, [isOpen]);

    const popupX = {
        initial: { x: '100%' },
        animate: { x: 0 },
        exit: { x: '100%' }
    };
    const popupY = {
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    variants={back ? popupX : popupY}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className={style.fullScreenPopup}
                    transition={{
                        duration: 0.1
                    }}
                    style={{ zIndex }}
                >
                    {!hiddenTop && (
                        <div className={style.top}>
                            {back ? (
                                <BackIcon className={style.back} onClick={back} />
                            ) : (
                                <div></div>
                            )}
                            {title}
                            {!hiddenClose ? (
                                <CloseIcon className={style.close} onClick={close} />
                            ) : (
                                ''
                            )}
                            {send && (
                                <div className={style.send} onClick={send}>
                                    {sendText || '傳送'}
                                </div>
                            )}
                        </div>
                    )}

                    <div className={style.main}>{children}</div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default FullScreenPopup;
