import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';
import { FcmMessage } from '@/types/notifyCenter';

type SnackMessage = {
    isOpen?: boolean;
    type?: string;
    message: string;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'right' | 'center';
};

type MessageModal = {
    content: string;
    open: boolean;
    type: MessageModalIcon;
};

type SetMessageModal = {
    content: string;
    type?: MessageModalIcon;
};

type MessageModalIcon = 'warning';

type ConfirmModal = {
    title: string;
    content?: string;
    open: boolean;
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
    backdropClickMode?: boolean;
};

type setConfirmModal = {
    title: string;
    content?: string;
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
    backdropClickMode?: boolean;
};

interface baseState {
    snackMessage: SnackMessage;
    setSnackMessage: (params: SnackMessage) => void;
    setClose: () => void;
    messageModal: MessageModal;
    setMessageModal: (params: SetMessageModal) => void;
    setCloseMessageModal: () => void;
    confirmModal: ConfirmModal;
    setConfirmModal: (params: setConfirmModal) => void;
    setCloseConfirmModal: () => void;
    validatePhoneModalVisible: boolean;
    setValidatePhoneModalVisible: (params: boolean) => void;
    userInfoCompletedModalVisible: boolean;
    setUserInfoCompletedModalVisible: (params: boolean) => void;
    fcmModalData: FcmMessage;
    setFcmModal: (params: FcmMessage) => void;
    closeFcmModal: () => void;
}

let isInit = true;
let useNotifyStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    snackMessage: {
        isOpen: false,
        message: '',
        vertical: 'bottom',
        horizontal: 'center'
    } as SnackMessage,
    setSnackMessage: (params: SnackMessage) => {
        set(state => ({
            snackMessage: {
                ...state.snackMessage,
                isOpen: true,
                type: 'normal',
                ...params
            }
        }));
    },
    setClose: () => {
        set(state => ({
            snackMessage: {
                isOpen: false,
                message: '',
                vertical: 'bottom',
                horizontal: 'center',
                type: state.snackMessage.type
            }
        }));
    },
    messageModal: {
        content: '',
        open: false,
        type: 'warning' as MessageModalIcon
    },
    setMessageModal: ({ content, type }: SetMessageModal) => {
        set(() => ({
            messageModal: {
                content,
                open: true,
                type: type || 'warning'
            }
        }));
    },
    setCloseMessageModal: () => {
        set(() => ({
            messageModal: {
                content: '',
                open: false,
                type: 'warning'
            }
        }));
    },
    confirmModal: {
        title: '',
        content: '',
        open: false,
        onConfirm: () => {},
        confirmText: '',
        cancelText: ''
    },
    setConfirmModal: ({
        title,
        content,
        onConfirm,
        confirmText,
        cancelText,
        backdropClickMode
    }: setConfirmModal) => {
        set(() => ({
            confirmModal: {
                title,
                content,
                open: true,
                onConfirm,
                confirmText,
                cancelText,
                backdropClickMode
            }
        }));
    },
    setCloseConfirmModal: () => {
        set(() => ({
            confirmModal: {
                title: '',
                content: '',
                open: false,
                onConfirm: () => {},
                confirmText: '',
                cancelText: '',
                backdropClickMode: false
            }
        }));
    },
    validatePhoneModalVisible: false,
    setValidatePhoneModalVisible: (params: boolean) => {
        set(() => ({
            validatePhoneModalVisible: params
        }));
    },
    userInfoCompletedModalVisible: false,
    setUserInfoCompletedModalVisible: (params: boolean) => {
        set(() => ({
            userInfoCompletedModalVisible: params
        }));
    },
    fcmModalData: {
        title: '',
        image: '',
        content: '',
        deep_link: {
            jump_to: ''
        }
    },
    setFcmModal: (params: FcmMessage) => {
        const urlParams = new URLSearchParams(window.location.search);
        const to = urlParams.get('to');
        if (to === params.deep_link.user_id) return;
        set(() => ({
            fcmModalData: params
        }));
    },
    closeFcmModal: () => {
        set(() => ({
            fcmModalData: {
                title: '',
                image: '',
                content: '',
                deep_link: {
                    jump_to: ''
                }
            }
        }));
    }
});

const createNotifyStore = () => {
    if (isInit) {
        useNotifyStore = initStore<baseState>(initialState);
        isInit = false;
    }
};

export { createNotifyStore, useNotifyStore };
