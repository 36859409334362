import Cookies from 'js-cookie';

import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';
import { detectBrowser } from '@/utils/detectBrowser';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<UserChoice>;
    prompt(): Promise<void>;
}

interface UserChoice {
    outcome: 'accepted' | 'dismissed';
    platform: string;
}

interface InitState {
    showPwaTip: boolean;
    envParams: string;
}
interface BaseState {
    envParams: string;
    loginPopup: boolean;
    setLoginPopup: (status?: boolean) => void;
    browser: string;
    isPWA: boolean;
    isFullscreen: boolean;
    setIsPWA: (status: boolean) => void;
    showPwaTip: boolean;
    setShowPwaTip: (status: boolean) => void;
    showPwaTipBottom: boolean;
    setShowPwaTipBottom: (status: boolean) => void;
    isIOS: boolean;
    deferredPrompt: BeforeInstallPromptEvent;
    setDeferredPrompt: (event: BeforeInstallPromptEvent) => void;
    installPWA: () => void;
}

interface NavigatorExtended extends Navigator {
    standalone?: boolean;
}

let useBaseStore: StoreWithSelectors<BaseState>;

const initialState = (set: (updater: (state: BaseState) => Partial<BaseState>) => void) => ({
    envParams: 'prod',
    loginPopup: false,
    setLoginPopup: (status?: boolean) => {
        set(state => ({
            loginPopup: typeof status === 'boolean' ? status : !state.loginPopup
        }));
    },
    browser: typeof window !== 'undefined' ? detectBrowser() : '',
    isPWA:
        typeof window !== 'undefined' &&
        ((window.navigator as NavigatorExtended).standalone ||
            window.matchMedia('(display-mode: standalone)').matches),
    isIOS:
        typeof window !== 'undefined' && /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()),
    isFullscreen:
        typeof window !== 'undefined' && window.matchMedia('(display-mode: fullscreen)').matches,
    showPwaTip: false,
    setIsPWA: (status: boolean) => {
        set(() => ({
            isPWA: status
        }));
    },
    setShowPwaTip: (status: boolean) => {
        set(() => ({
            showPwaTip: status
        }));
    },
    showPwaTipBottom: false,
    setShowPwaTipBottom: (status: boolean) => {
        set(() => ({
            showPwaTipBottom: status
        }));
    },
    deferredPrompt: {} as BeforeInstallPromptEvent,
    setDeferredPrompt: (event: BeforeInstallPromptEvent) => {
        set(() => ({
            deferredPrompt: event
        }));
    },
    installPWA: () => {
        const isPass = Cookies.get('pwaTipBottom');
        if (isPass) return;
        const isIOS = useBaseStore.getState().isIOS;
        const deferredPrompt = useBaseStore.getState().deferredPrompt;
        if (isIOS) {
            useBaseStore.getState().setShowPwaTipBottom(true);
        } else {
            if (deferredPrompt) {
                deferredPrompt.prompt();
                deferredPrompt.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === 'accepted') {
                        // eslint-disable-next-line -- log
                        console.log('User accepted the install prompt');
                    } else {
                        // eslint-disable-next-line -- log
                        console.log('User dismissed the install prompt');
                    }
                });
            }
        }
    }
});

const createBaseStore = (init: InitState) => {
    useBaseStore = initStore<BaseState>(initialState, init);
};

export { createBaseStore, useBaseStore };
